.modal-confirm{
  .ant-modal-confirm-body-wrapper{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .ant-modal-confirm-btns{
    transform: scale(-1,1);
    .ant-btn{
      transform: scale(-1,1);
    }
    .ant-btn .ant-btn-primary{
      transform: scale(-1,1);
    }
  }
}
@hack: true; @import '~@wii-fe/wau/es/style/themes/index.less';